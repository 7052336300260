import axios from 'axios'
import type {
  AllocationSupplier,
  SupplierContact,
  SupplierLocation,
  SupplierSpecialization,
  SupplierType,
} from '@/types/supplierType'

export default class {
  /**
   * Supplier
   */
  static async postSupplier(supplier: Omit<SupplierType, 'id'>) {
    return axios.post<SupplierType>(`suppliers`, supplier)
  }

  static async getSuppliers({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<SupplierType[]>('suppliers', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getSupplierById(id: string) {
    return axios.get<SupplierType>(`suppliers/${id}`)
  }

  static async getAllocationSuppliersByProduct(
    municipalityId: string,
    productId: string,
    startDate: string,
    isAllocationRequest: boolean,
    clientId?: string,
  ) {
    return axios.get<AllocationSupplier[]>(
      `municipalities/${municipalityId}/${isAllocationRequest ? '' : `clients/${clientId}/`}${isAllocationRequest ? 'allocation_requests' : 'allocations'}/product/${productId}/suppliers/${startDate}`,
    )
  }

  static async putSupplier(supplier: SupplierType) {
    return axios.put<SupplierType>(`suppliers/${supplier.id}`, supplier)
  }

  /**
   * Contact
   */
  static postSupplierContact(
    supplierId: string,
    contact: Omit<SupplierContact, 'id'>,
  ) {
    return axios.post<SupplierContact>(
      `/suppliers/${supplierId}/supplier_contacts`,
      contact,
    )
  }

  static async getSupplierContactById(supplierId: string, contactId: string) {
    return axios.get<SupplierContact>(
      `/suppliers/${supplierId}/supplier_contacts/${contactId}`,
    )
  }

  static async putSupplierContact(
    supplierId: string,
    contact: SupplierContact,
  ) {
    return axios.put<SupplierContact>(
      `/suppliers/${supplierId}/supplier_contacts/${contact.id}`,
      contact,
    )
  }

  static async deleteSupplierContact(
    supplierId: string,
    supplierContactId: string,
  ) {
    return axios.delete(
      `/suppliers/${supplierId}/supplier_contacts/${supplierContactId}`,
    )
  }

  /**
   * Locations
   */
  static async postSupplierLocation(
    supplierId: string,
    location: Omit<SupplierLocation, 'id'>,
  ) {
    return axios.post<SupplierLocation>(
      `/suppliers/${supplierId}/supplier_locations`,
      location,
    )
  }

  static async getSupplierLocationById(supplierId: string, locationId: string) {
    return axios.get<SupplierLocation>(
      `/suppliers/${supplierId}/supplier_locations/${locationId}`,
    )
  }

  static async putSupplierLocation(
    supplierId: string,
    location: SupplierLocation,
  ) {
    return axios.put<SupplierLocation>(
      `/suppliers/${supplierId}/supplier_locations/${location.id}`,
      location,
    )
  }

  static async deleteSupplierLocation(
    supplierId: string,
    supplierLocationId: string,
  ) {
    return axios.delete(
      `/suppliers/${supplierId}/supplier_locations/${supplierLocationId}`,
    )
  }

  /**
   * Specialization
   */
  static async postSupplierSpecialization(
    supplierId: string,
    specialization: Omit<SupplierSpecialization, 'id'>,
  ) {
    return axios.post<SupplierSpecialization>(
      `/suppliers/${supplierId}/supplier_specializations`,
      specialization,
    )
  }

  static async getSupplierSpecializationById(
    supplierId: string,
    specializationId: string,
  ) {
    return axios.get<SupplierSpecialization>(
      `/suppliers/${supplierId}/supplier_specializations/${specializationId}`,
    )
  }

  static async putSupplierSpecialization(
    supplierId: string,
    specialization: SupplierSpecialization,
  ) {
    return axios.put<SupplierSpecialization>(
      `/suppliers/${supplierId}/supplier_specializations/${specialization.id}`,
      specialization,
    )
  }

  static async deleteSupplierSpecialization(
    supplierId: string,
    supplierSpecializationId: string,
  ) {
    return axios.delete(
      `/suppliers/${supplierId}/supplier_specializations/${supplierSpecializationId}`,
    )
  }
}
