import type { EntityRelation } from '@/types/types'

export type ProductType = {
  id: string
  wmoCategory: ProductWmoCategory
  wmoCode?: string
  code: string
  name: string
  unit: ProductUnits
  frequency: ProductFrequency
  amount?: number
  amountCanChange: boolean
  min?: number
  max?: number
  unlimitedBudget: boolean
  icon?: string
  productSubgroups: Array<ProductSubgroup>
  claimCategory: ClaimCategory
  iv3Task: Iv3Task
  jw: boolean
  prices?: ProductPrice[]
  currentPrice?: ProductPrice['price']
  type: ProductTypeOf
  quotationAllowed: boolean
  untilRequired: boolean
  roundRobinType: RoundRobinTypeOf
  customFields: CustomField[]
  relations?: EntityRelation[]
}

export type ProductTypeFlat = Omit<ProductType, 'productSubgroups'> & {
  productSubgroups: Array<string>
}

export type ProductSubgroup = {
  id: string
  code: string
  name: string
  icon?: string
  productGroup: ProductGroup
}

export type ProductSubgroupFlat = Omit<ProductSubgroup, 'productGroup'> & {
  productGroup: string
}

export type ProductGroup = {
  id: string
  icon?: string
  name: string
}

export type ProductTreeProducts = ProductGroup & {
  productSubgroups: Array<
    Pick<ProductSubgroup, 'id' | 'name' | 'icon'> & {
      products: Array<
        Pick<ProductType, 'id' | 'wmoCode' | 'name' | 'jw' | 'type'>
      >
    }
  >
}

export type ProductTreeSubGroups = ProductGroup & {
  productSubgroups: Array<Pick<ProductSubgroup, 'id' | 'name' | 'icon'>>
}

export type AllocationProductTree = Array<
  ProductGroup & {
    productSubgroups: Array<
      Pick<ProductSubgroup, 'id' | 'name' | 'icon'> & {
        products: Array<AllocationProduct>
      }
    >
  }
>

export type AllocationProduct = Pick<
  ProductType,
  'id' | 'wmoCode' | 'code' | 'name' | 'icon' | 'wmoCategory' | 'roundRobinType'
> & {
  contracts: number
  labels?: string[]
}

export type AllocationProductScope = {
  product: Pick<ProductType, 'unit' | 'frequency' | 'min' | 'max'> & {
    fixed: Pick<ProductType, 'amountCanChange'>
  }
  volumes: Array<number>
  client: {
    volume: number
    labels: Array<string>
  }
  value?: number
}

export type ProductPrice = {
  id: string
  since: string
  until?: string
  price: number
}

export type CustomField = {
  id: string
  label: string
  type: CustomFieldTypeOf
  required: boolean
}

export type CustomFieldDTO = Omit<CustomField, 'id'>

export enum CustomFieldTypeOf {
  STRING = 'string',
  TEXTAREA = 'textarea',
  FILE = 'file',
}

export enum ProductTypeOf {
  SPECIFIC = 'specific',
  UNSPECIFIC = 'unspecific',
}

export enum ProductWmoCategory {
  _01 = '01',
  _02 = '02',
  _03 = '03',
  _04 = '04',
  _05 = '05',
  _06 = '06',
  _07 = '07',
  _08 = '08',
  _09 = '09',
  _10 = '10',
  _11 = '11',
  _12 = '12',
  _13 = '13',
  _14 = '14',
  _15 = '15',
  _16 = '16',
  _17 = '17',
  _18 = '18',
  _31 = '31',
  _32 = '32',
  _33 = '33',
  _34 = '34',
  _35 = '35',
  _36 = '36',
  _37 = '37',
  _38 = '38',
  _40 = '40',
  _41 = '41',
  _42 = '42',
  _43 = '43',
  _44 = '44',
  _45 = '45',
  _46 = '46',
  _47 = '47',
  _48 = '48',
  _49 = '49',
  _50 = '50',
  _51 = '51',
  _52 = '52',
  _53 = '53',
  _54 = '54',
  _55 = '55',
}

export enum RoundRobinSupplierCheck {
  AGB = 'ROUND_ROBIN',
}

export enum RoundRobinTypeOf {
  NONE = 'none',
  REQUIRED = 'required',
  OPTIONAL = 'optional',
}

export enum ProductUnits {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  DAY_PART = 'day_part',
  PIECES_EFFORT = 'pieces_effort',
  PIECES_OUTPUT = 'pieces_output',
  EURO = 'euro',
}

export enum ProductFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  TOTAL = 'total_within_duration_of_allocation',
}

export enum ClaimCategory {
  JW_24_UURS_VERBLIJF = 'jw_24_uurs_verblijf',
  JW_AMBULANTE_JEUGDHULP = 'jw_ambulante_jeugdhulp',
  JW_BEGELEIDING_EN_VERZORGING = 'jw_begeleiding_en_verzorging',
  JW_BEHANDELINGSGERICHTE_DAGBESTEDING = 'jw_behandelingsgerichte_dagbesteding',
  JW_BESCHERMD_WONEN = 'jw_beschermd_wonen',
  JW_DAGBESTEDING = 'jw_dagbesteding',
  JW_DRANG = 'jw_drang',
  JW_DRIEMILIEUVOORZIENING = 'jw_driemilieuvoorziening',
  JW_EED = 'jw_eed',
  JW_GESLOTEN_JEUGDHULP = 'jw_gesloten_jeugdhulp',
  JW_GEZINSHUIZEN = 'jw_gezinshuizen',
  JW_JEUGDRECLASSERING = 'jw_jeugdreclassering',
  JW_KINDERBESCHERMINGSMAATREGEL = 'jw_kinderbeschermingsmaatregel',
  JW_KLINISCHE_JEUGD_GGZ = 'jw_klinische_jeugd_ggz',
  JW_KORTDUREND_VERBLIJF = 'jw_kortdurend_verblijf',
  JW_LTA = 'jw_lta',
  JW_PLEEGZORG = 'jw_pleegzorg',
  JW_OVERIGE_ACTIVITEITEN_JEUGDBESCHERMING = 'jw_overige_activiteiten_jeugdbescherming',
  JW_VERVOER = 'jw_vervoer',
  PW_TAALTOETSEN = 'pw_taaltoetsen',
  WI_INBURGERING = 'wi_inburgering',
  WI_ONDERWIJSROUTE = 'wi_onderwijsroute',
  WMO_BEGELEIDING_EN_VERZORGING = 'wmo_begeleiding_en_verzorging',
  WMO_BESCHERMD_WONEN_WMO = 'wmo_beschermd_wonen_wmo',
  WMO_DAGBESTEDING = 'wmo_dagbesteding',
  WMO_HUISHOUDELIJKE_HULP = 'wmo_huishoudelijke_hulp',
  WMO_MAATSCHAPPELIJKE_OPVANG = 'wmo_maatschappelijke_opvang',
  WMO_KORTDUREND_VERBLIJF = 'wmo_kortdurend_verblijf',
  WMO_OVERIGE_HULPMIDDELEN = 'wmo_overige_hulpmiddelen',
  WMO_ROLSTOELEN = 'wmo_rolstoelen',
  WMO_TRAPLIFTEN = 'wmo_trapliften',
  WMO_VERVOER = 'wmo_vervoer',
  WMO_VERVOERSVOORZIENINGEN = 'wmo_vervoersvoorzieningen',
  WEB_VOLWASSENENEDUCATIE = 'web_volwasseneneducatie',
  WMO_WOONVOORZIENINGEN = 'wmo_woonvoorzieningen',
  WMO_WONINGAANPASSINGEN = 'wmo_woningaanpassingen',
  WMO_ZELFSTANDIG_LEVEN = 'wmo_zelfstandig_leven',
}

export enum Iv3Task {
  BEGELEIDING_WMO = 'begeleiding_wmo',
  BESCHERMD_WONEN_WMO = 'beschermd_wonen_wmo',
  DAGBESTEDING_WMO = 'dagbesteding_wmo',
  GESLOTEN_PLAATSING = 'gesloten_plaatsing',
  GEZINSGERICHT = 'gezinsgericht',
  HUISHOUDELIJKE_HULP_WMO = 'huishoudelijke_hulp_wmo',
  JEUGDBESCHERMING = 'jeugdbescherming',
  JEUGD_BEHANDELING_GGZ_ZONDER_VERBLIJF = 'jeugd_behandeling_ggz_zonder_verblijf',
  JEUGDHULP_BEGELEIDING = 'jeugdhulp_begeleiding',
  JEUGDHULP_BEHANDELING = 'jeugdhulp_behandeling',
  JEUGDHULP_CRISIS_LTA_GGZ_VERBLIJF = 'jeugdhulp_crisis_lta_ggz_verblijf',
  JEUGDHULP_DAGBESTEDING = 'jeugdhulp_dagbesteding',
  JEUGDHULP_MET_VERBLIJF_OVERIG = 'jeugdhulp_met_verblijf_overig',
  JEUGDHULP_ZONDER_VERBLIJF_OVERIG = 'jeugdhulp_zonder_verblijf_overig',
  JEUGDRECLASSERING = 'jeugdreclassering',
  MAATSCHAPPELIJKE_EN_VROUWENOPVANG_WMO = 'maatschappelijke_en_vrouwenopvang_wmo',
  MAATWERKVOORZIENINGEN_WMO = 'maatwerkvoorzieningen_wmo',
  ONDERWIJSBELEID_EN_LEERLINGZAKEN = 'onderwijsbeleid_en_leerlingzaken',
  OVERIGE_MAATWERKARRANGEMENTEN_WMO = 'overige_maatwerkarrangementen_wmo',
  PLEEGZORG = 'pleegzorg',
  SAMENKRACHT_EN_BURGERPARTICIPATIE = 'samenkracht_en_burgerparticipatie',
  TOEGANG_EN_EERSTELIJNSVOORZIENINGEN = 'toegang_en_eerstelijnsvoorzieningen',
}
