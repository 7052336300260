import axios from 'axios'
import type {
  AllocationRequestType,
  AllocationRequestDTO,
  AllocationRequestDeny,
} from '@/types/allocationRequestType'

export default class {
  static async getAllocationRequests({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<AllocationRequestType[]>('allocation_requests', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getAllocationRequestById(id: string) {
    return axios.get<AllocationRequestType>(`allocation_requests/${id}`)
  }

  static async postAllocationRequest(
    municipalityId: string,
    AllocationRequest: AllocationRequestDTO,
  ) {
    return axios.post<null>(
      `municipalities/${municipalityId}/allocation_requests`,
      AllocationRequest,
    )
  }

  static async denyAllocationRequest(allocationRequest: AllocationRequestDeny) {
    return axios.put<AllocationRequestType>(
      `allocation_requests/${allocationRequest.id}`,
      allocationRequest,
    )
  }
}
