import axios from 'axios'
import type { QuotationDTO, QuotationType } from '@/types/quotationType'

export default class {
  static async getQuotations({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<QuotationType[]>('quotations', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getQuotationById(id: string) {
    return axios.get<QuotationType>(`quotations/${id}`)
  }

  static async postQuotation(quotation: QuotationDTO) {
    return axios.post<QuotationType>('quotations', {
      ...quotation,
      amount: quotation.amount ? quotation.amount * 100 : undefined,
    })
  }

  static async putQuotation(quotation: QuotationDTO) {
    return axios.put<QuotationType>(`quotations/${quotation.id}`, {
      ...quotation,
      amount: quotation.amount ? quotation.amount * 100 : undefined,
    })
  }
}
