import { defineStore } from 'pinia'
import FulfillmentService from '@/services/FulfillmentService'
import {
  type FloatingFulfillmentPut,
  type FulfillmentPut,
  type FulfillmentType,
} from '@/types/fulfillmentType'
import type { EntityTypeOf } from '@/types/types'

export const useFulfillmentStore = defineStore({
  id: 'fulfillments',
  state: (): {
    fulfillments: Map<
      string,
      FulfillmentType & { timestamp?: number, outdated?: boolean }
    >
  } => ({
    fulfillments: new Map<string, FulfillmentType>(),
  }),
  actions: {
    async fetchFulfillmentsByEntityAndId(
      entityType: EntityTypeOf,
      entityId: string,
      page: number,
    ) {
      const { data } = await FulfillmentService.getFulfillmentsByEntityAndId(
        entityType,
        entityId,
        page,
      )

      if (data) {
        data.forEach((x) => this.fulfillments.set(x.id, x))
      }

      return data
    },

    async fetchFulfillmentById(id: string, cached = true) {
      const local = this.fulfillments.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await FulfillmentService.getFulfillmentById(id)

        if (data) {
          this.fulfillments.set(id, {
            ...(local?.outdated ? local : {}),
            ...data,
            timestamp: new Date().getTime(),
            outdated: false,
          })
        }

        return data
      }

      return local
    },

    async updateFulfillment(fulfillment: FulfillmentPut) {
      const { data } = await FulfillmentService.putFulfillment(fulfillment)

      if (data) this.fulfillments.set(fulfillment.id, data)

      return data
    },

    async updateFloatingFulfillment(fulfillment: FloatingFulfillmentPut) {
      return (await FulfillmentService.putFloatingFulfillment(fulfillment)).data
    },

    async markFulfillmentAsOutdated(id: string, watching: boolean) {
      const local = this.fulfillments.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchFulfillmentById(id, false)
      }
    },
  },
  getters: {
    findOrThrowFulfillmentById: (state) => {
      return (id: string) => {
        const fulfillment = state.fulfillments?.get(id)

        if (!fulfillment) throw new Error('Fulfillment not found')

        return fulfillment
      }
    },

    findFulfillmentsByEntityAndId: (state) => {
      return (
        entityType:
          | EntityTypeOf.ALLOCATION
          | EntityTypeOf.CLAIM
          | EntityTypeOf.CLIENT,
        entityId: string,
      ) => {
        return Array.from<FulfillmentType>(state.fulfillments.values()).filter(
          (x) => x[entityType].id === entityId,
        )
      }
    },
  },
})
