import { defineStore } from 'pinia'
import {
  type AllocationRequestType,
  type AllocationRequestDTO,
  type AllocationRequestDeny,
  AllocationRequestStatus,
} from '@/types/allocationRequestType'
import AllocationRequestService from '@/services/AllocationRequestService'

export const useAllocationRequestStore = defineStore({
  id: 'allocationrequests',
  state: (): {
    data: Map<
      string,
      AllocationRequestType & { timestamp?: number, outdated?: boolean }
    >
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
    allocationRequestParams?: string
  } => ({
    data: new Map(),
    page: 1,
    filters: {
      allocationRequestStatus: AllocationRequestStatus.REQUESTED,
    },
    sortBy: undefined,

    allocationRequestParams: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return AllocationRequestService.getAllocationRequests({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async addNewAllocationRequest(
      municipalityId: string,
      allocationRequest: AllocationRequestDTO,
    ) {
      return (
        await AllocationRequestService.postAllocationRequest(
          municipalityId,
          allocationRequest,
        )
      ).data
    },

    async fetchAllocationRequestById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } =
          await AllocationRequestService.getAllocationRequestById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async denyAllocationRequest(allocationRequest: AllocationRequestDeny) {
      const { data } =
        await AllocationRequestService.denyAllocationRequest(allocationRequest)

      this.data.set(allocationRequest.id, data)

      return data
    },

    async markAllocationRequestAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchAllocationRequestById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) =>
      Array.from<AllocationRequestType>(state.data.values()),

    findOrThrowAllocationRequestById: (state) => {
      return (id: string) => {
        const request = state.data.get(id)

        if (!request) throw new Error()

        return request
      }
    },
  },
})
