import { createRouter, createWebHistory } from 'vue-router'
import MainView from '@/views/MainView.vue'
import NProgress from 'nprogress'
import { RolePermission } from '@/types/roleType'
import { isAuthorized, isMunicipality, isSupplier } from '@/utils/isAuthorized'
import { useKnowledgeBaseStore } from '@/stores/knowledgeBase'
import { useUserStore } from '@/stores/users'

const initializeRouter = () => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        name: 'Home',
        path: '/',
        meta: {
          title: 'Home',
        },
        component: MainView,
        children: [
          {
            name: 'HomeDashboard',
            path: '',
            meta: {},
            component: () => import('@/views/home/HomeDashboard.vue'),
          },
        ],
      },
      {
        name: 'Clients',
        path: '/client',
        meta: {},
        component: MainView,
        children: [
          {
            name: 'ClientsIndex',
            path: '/client',
            component: () => import('@/views/client/ClientIndex.vue'),
            children: [
              {
                name: 'ClientAllocationAdd',
                path: ':clientId/toewijzing/nieuw/:allocationRequestId?',
                meta: {
                  requiresAuth: RolePermission.ALLOCATION_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/client/allocation/ClientAllocationAdd.vue'),
              },
              {
                name: 'ClientRequestAllocationAdd',
                path: ':clientId/aanmelding/nieuw',
                meta: {
                  requiresAuth: RolePermission.ALLOCATION_REQUEST_WRITE,
                },
                component: () =>
                  import(
                    '@/views/client/allocation/ClientRequestAllocationAdd.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        name: 'Tasks',
        path: '/taken',
        meta: {},
        component: MainView,
        children: [
          {
            name: 'TaskDashboard',
            path: '',
            meta: {},
            component: () => import('@/views/task/TaskDashboard.vue'),
          },
          {
            name: 'TaskDetail',
            path: ':id',
            meta: {
              trackEntityId: true,
            },
            component: () => import('@/views/task/TaskDetail.vue'),
          },
        ],
      },
      {
        name: 'Lists',
        path: '/lijsten',
        component: MainView,
        children: [
          {
            name: 'ListDashboard',
            path: '',
            component: () => import('@/views/list/ListDashboardView.vue'),
          },
          {
            name: 'AllocationIndex',
            path: 'toewijzingen',
            meta: {
              requiresAuth: RolePermission.ALLOCATION_READ,
            },
            component: () =>
              import('@/views/list/allocation/AllocationIndex.vue'),
            children: [
              {
                name: 'AllocationList',
                path: '',
                component: () =>
                  import('@/views/list/allocation/AllocationList.vue'),
              },
              {
                name: 'AllocationDetail',
                path: ':id',
                component: () =>
                  import('@/views/list/allocation/AllocationDetail.vue'),
                meta: {
                  trackEntityId: true,
                },
              },
              {
                name: 'AllocationEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.ALLOCATION_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/allocation/AllocationEdit.vue'),
              },
              {
                name: 'AllocationRevoke',
                path: ':id/intrekken',
                meta: {
                  requiresAuth: RolePermission.ALLOCATION_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/allocation/AllocationRevoke.vue'),
              },
            ],
          },
          {
            name: 'AllocationRequestIndex',
            path: 'aanmeldingen',
            meta: {
              requiresAuth: RolePermission.ALLOCATION_REQUEST_READ,
            },
            component: () =>
              import(
                '@/views/list/allocation-request/AllocationRequestIndex.vue'
              ),
            children: [
              {
                name: 'AllocationRequestList',
                path: '',
                component: () =>
                  import(
                    '@/views/list/allocation-request/AllocationRequestList.vue'
                  ),
              },
              {
                name: 'AllocationRequestDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/allocation-request/AllocationRequestDetail.vue'
                  ),
              },
              {
                name: 'AllocationRequestDeny',
                path: ':id/afwijzen',
                meta: {
                  requiresAuth: RolePermission.ALLOCATION_REQUEST_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/allocation-request/AllocationRequestDeny.vue'
                  ),
              },
            ],
          },
          {
            name: 'QuotationIndex',
            path: 'offertes',
            meta: {
              requiresAuth: RolePermission.QUOTATION_READ,
            },
            component: () =>
              import('@/views/list/quotation/QuotationIndex.vue'),
            children: [
              {
                name: 'QuotationList',
                path: '',
                component: () =>
                  import('@/views/list/quotation/QuotationList.vue'),
              },
              {
                name: 'QuotationDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/quotation/QuotationDetail.vue'),
              },
              {
                name: 'QuotationAdd',
                path: 'aanmaken',
                component: () =>
                  import('@/views/list/quotation/QuotationAdd.vue'),
              },
              {
                name: 'QuotationEdit',
                path: ':id/wijzigen',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/quotation/QuotationEdit.vue'),
              },
              {
                name: 'QuotationSupplierAdd',
                path: ':id/bedrag-toevoegen',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/quotation/QuotationSupplierAdd.vue'),
              },
            ],
          },
          {
            name: 'ComplaintIndex',
            path: 'klachten',
            meta: {
              requiresAuth: RolePermission.COMPLAINT_READ,
            },
            component: () =>
              import('@/views/list/complaint/ComplaintIndex.vue'),
            children: [
              {
                name: 'ComplaintList',
                path: '',
                component: () =>
                  import('@/views/list/complaint/ComplaintList.vue'),
              },
              {
                name: 'ComplaintDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/complaint/ComplaintDetail.vue'),
              },
              {
                name: 'ComplaintAdd',
                path: 'aanmaken',
                component: () =>
                  import('@/views/list/complaint/ComplaintAdd.vue'),
              },
              {
                name: 'ComplaintEdit',
                path: ':id/wijzigen',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/complaint/ComplaintEdit.vue'),
              },
              {
                name: 'ComplaintResolve',
                path: ':id/afhandelen',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/complaint/ComplaintResolve.vue'),
              },
            ],
          },
          {
            name: 'MunicipalityIndex',
            path: 'gemeenten',
            meta: {
              requiresAuth: RolePermission.MUNICIPALITY_READ,
            },
            component: () =>
              import('@/views/list/municipality/MunicipalityIndex.vue'),
            children: [
              {
                name: 'MunicipalityList',
                path: '',
                component: () =>
                  import('@/views/list/municipality/MunicipalityList.vue'),
              },
              {
                name: 'MunicipalityAdd',
                path: 'aanmaken',
                meta: {
                  requiresAuth: RolePermission.MUNICIPALITY_WRITE,
                },
                component: () =>
                  import('@/views/list/municipality/MunicipalityAdd.vue'),
              },
              {
                name: 'MunicipalityDetail',
                path: ':id',
                meta: {
                  requiresAuth: RolePermission.MUNICIPALITY_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/municipality/MunicipalityDetail.vue'),
              },
              {
                name: 'MunicipalityContactAdd',
                path: ':id/contact/aanmaken',
                meta: {
                  requiresAuth: RolePermission.MUNICIPALITY_WRITE,
                },
                component: () =>
                  import(
                    '@/views/list/municipality/contacts/MunicipalityContactsAdd.vue'
                  ),
              },
              {
                name: 'MunicipalityContactEdit',
                path: ':id/contact/:contactId',
                meta: {
                  requiresAuth: RolePermission.MUNICIPALITY_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/municipality/contacts/MunicipalityContactsEdit.vue'
                  ),
              },
              {
                name: 'MunicipalityEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.MUNICIPALITY_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/municipality/MunicipalityEdit.vue'),
              },
            ],
          },
          {
            name: 'ClientIndex',
            path: 'clienten',
            meta: {
              requiresAuth: RolePermission.CLIENT_READ,
            },
            component: () => import('@/views/list/client/ClientIndex.vue'),
            children: [
              {
                name: 'ClientList',
                path: '',
                component: () => import('@/views/list/client/ClientList.vue'),
              },
              {
                name: 'ClientAdd',
                path: 'nieuw',
                meta: {
                  requiresAuth: RolePermission.CLIENT_WRITE,
                },
                component: () => import('@/views/list/client/ClientAdd.vue'),
              },
              {
                name: 'ClientDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () => import('@/views/list/client/ClientDetail.vue'),
              },
              {
                name: 'ClientEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.CLIENT_WRITE,
                  trackEntityId: true,
                },
                component: () => import('@/views/list/client/ClientEdit.vue'),
              },
              {
                name: 'ClientContactEdit',
                path: ':id/contactperoon/:contactId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.CLIENT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/client/contact/ClientContactEdit.vue'),
              },
              {
                name: 'ClientContactAdd',
                path: ':id/contactperoon/nieuw',
                meta: {
                  requiresAuth: RolePermission.CLIENT_WRITE,
                },
                component: () =>
                  import('@/views/list/client/contact/ClientContactAdd.vue'),
              },
              {
                name: 'ClientAddressEdit',
                path: ':id/adres/:addressId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.CLIENT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/client/address/ClientAddressEdit.vue'),
              },
              {
                name: 'ClientAddressAdd',
                path: ':id/adres/nieuw',
                meta: {
                  requiresAuth: RolePermission.CLIENT_WRITE,
                },
                component: () =>
                  import('@/views/list/client/address/ClientAddressAdd.vue'),
              },
            ],
          },
          {
            name: 'ContractIndex',
            path: 'contracten',
            component: () => import('@/views/list/contract/ContractIndex.vue'),
            meta: {
              requiresAuth: RolePermission.CONTRACT_READ,
            },
            children: [
              {
                name: 'ContractList',
                path: '',
                component: () =>
                  import('@/views/list/contract/ContractList.vue'),
              },
              {
                name: 'ContractAdd',
                path: 'nieuw',
                meta: {
                  requiresAuth: RolePermission.CONTRACT_WRITE,
                },
                component: () =>
                  import('@/views/list/contract/ContractAdd.vue'),
              },
              {
                name: 'ContractDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/contract/ContractDetail.vue'),
              },
              {
                name: 'ContractEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.CONTRACT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/contract/ContractEdit.vue'),
              },
              {
                name: 'ContractExclusionEdit',
                path: ':id/uitsluiting/:exclusionId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.CONTRACT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/contract/exclusion/ContractExclusionEdit.vue'
                  ),
              },
              {
                name: 'ContractExclusionAdd',
                path: ':id/uitsluiting/nieuw',
                meta: {
                  requiresAuth: RolePermission.CONTRACT_WRITE,
                },
                component: () =>
                  import(
                    '@/views/list/contract/exclusion/ContractExclusionAdd.vue'
                  ),
              },
              {
                name: 'ContractPriceAdd',
                path: ':id/prices/:productPriceId/nieuw',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                },
                component: () =>
                  import('@/views/list/contract/price/ContractPriceAdd.vue'),
              },
              {
                name: 'ContractPriceEdit',
                path: ':id/prices/:productId/:contractPriceId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/contract/price/ContractPriceEdit.vue'),
              },
            ],
          },
          {
            name: 'SupplierIndex',
            path: 'aanbieders',
            meta: {
              requiresAuth: RolePermission.SUPPLIER_READ,
            },
            component: () => import('@/views/list/supplier/SupplierIndex.vue'),
            children: [
              {
                name: 'SupplierList',
                path: '',
                component: () =>
                  import('@/views/list/supplier/SupplierList.vue'),
              },
              {
                name: 'SupplierAdd',
                path: 'nieuw',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                },
                component: () =>
                  import('@/views/list/supplier/SupplierAdd.vue'),
              },
              {
                name: 'SupplierDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/supplier/SupplierDetail.vue'),
              },
              {
                name: 'SupplierEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/supplier/SupplierEdit.vue'),
              },
              {
                name: 'SupplierContactEdit',
                path: ':id/contact/:contactId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/supplier/contact/SupplierContactEdit.vue'
                  ),
              },
              {
                name: 'SupplierContactAdd',
                path: ':id/contact/nieuw',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                },
                component: () =>
                  import(
                    '@/views/list/supplier/contact/SupplierContactAdd.vue'
                  ),
              },
              {
                name: 'SupplierLocationEdit',
                path: ':id/locatie/:locationId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/supplier/location/SupplierLocationEdit.vue'
                  ),
              },
              {
                name: 'SupplierLocationAdd',
                path: ':id/locatie/nieuw',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                },
                component: () =>
                  import(
                    '@/views/list/supplier/location/SupplierLocationAdd.vue'
                  ),
              },
              {
                name: 'SupplierSpecializationEdit',
                path: ':id/specialisatie/:specializationId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/supplier/specialization/SupplierSpecializationEdit.vue'
                  ),
              },
              {
                name: 'SupplierSpecializationAdd',
                path: ':id/specialisatie/nieuw',
                meta: {
                  requiresAuth: RolePermission.SUPPLIER_WRITE,
                },
                component: () =>
                  import(
                    '@/views/list/supplier/specialization/SupplierSpecializationAdd.vue'
                  ),
              },
            ],
          },
          {
            name: 'ProductenIndex',
            path: 'producten',
            meta: {
              requiresAuth: RolePermission.PRODUCT_READ,
            },
            component: () => import('@/views/list/product/ProductIndex.vue'),
            children: [
              {
                name: 'ProductList',
                path: '',
                component: () => import('@/views/list/product/ProductList.vue'),
              },
              {
                name: 'ProductAdd',
                path: 'nieuw',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                },
                component: () => import('@/views/list/product/ProductAdd.vue'),
              },
              {
                name: 'ProductDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/product/ProductDetail.vue'),
              },
              {
                name: 'ProductEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                  trackEntityId: true,
                },
                component: () => import('@/views/list/product/ProductEdit.vue'),
              },
              {
                name: 'ProductCustomFieldAdd',
                path: ':id/variabele-velden/nieuw',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                },
                component: () =>
                  import(
                    '@/views/list/product/customField/ProductCustomFieldAdd.vue'
                  ),
              },
              {
                name: 'ProductCustomFieldEdit',
                path: ':id/variabele-velden/:customFieldId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import(
                    '@/views/list/product/customField/ProductCustomFieldEdit.vue'
                  ),
              },
              {
                name: 'ProductPriceAdd',
                path: ':id/prices/nieuw',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                },
                component: () =>
                  import('@/views/list/product/price/ProductPriceAdd.vue'),
              },
              {
                name: 'ProductPriceEdit',
                path: ':id/prices/:priceId/wijzigen',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_WRITE,
                  trackEntityId: true,
                },
                component: () =>
                  import('@/views/list/product/price/ProductPriceEdit.vue'),
              },
              {
                name: 'ProductGroupIndex',
                path: 'groepen',
                meta: {
                  requiresAuth: RolePermission.PRODUCT_GROUP_READ,
                },
                component: () =>
                  import('@/views/list/product/group/ProductGroupIndex.vue'),
                children: [
                  {
                    name: 'ProductGroupList',
                    path: '',
                    component: () =>
                      import('@/views/list/product/group/ProductGroupList.vue'),
                  },
                  {
                    name: 'ProductGroupAdd',
                    path: 'nieuw',
                    meta: {
                      requiresAuth: RolePermission.PRODUCT_GROUP_WRITE,
                    },
                    component: () =>
                      import('@/views/list/product/group/ProductGroupAdd.vue'),
                  },
                  {
                    name: 'ProductGroupDetail',
                    path: ':id',
                    meta: {
                      trackEntityId: true,
                    },
                    component: () =>
                      import(
                        '@/views/list/product/group/ProductGroupDetail.vue'
                      ),
                  },
                  {
                    name: 'ProductGroupEdit',
                    path: ':id/wijzigen',
                    meta: {
                      requiresAuth: RolePermission.PRODUCT_GROUP_WRITE,
                      trackEntityId: true,
                    },
                    component: () =>
                      import('@/views/list/product/group/ProductGroupEdit.vue'),
                  },
                  {
                    name: 'ProductSubgroupIndex',
                    path: ':groupId/subgroepen',
                    component: () =>
                      import(
                        '@/views/list/product/subgroup/ProductSubgroupIndex.vue'
                      ),
                    children: [
                      {
                        name: 'ProductSubgroupAdd',
                        path: 'nieuw',
                        component: () =>
                          import(
                            '@/views/list/product/subgroup/ProductSubgroupAdd.vue'
                          ),
                      },
                      {
                        name: 'ProductSubgroupDetail',
                        path: ':id',
                        meta: {
                          trackEntityId: true,
                        },
                        component: () =>
                          import(
                            '@/views/list/product/subgroup/ProductSubgroupDetail.vue'
                          ),
                      },
                      {
                        name: 'ProductSubgroupEdit',
                        path: ':id/wijzigen',
                        meta: {
                          trackEntityId: true,
                        },
                        component: () =>
                          import(
                            '@/views/list/product/subgroup/ProductSubgroupEdit.vue'
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'ClaimIndex',
            path: 'declaraties',
            meta: {
              requiresAuth: RolePermission.CLAIM_READ,
            },
            component: () => import('@/views/list/claim/ClaimIndex.vue'),
            children: [
              {
                name: 'ClaimList',
                path: '',
                component: () => import('@/views/list/claim/ClaimList.vue'),
              },
              {
                name: 'ClaimDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () => import('@/views/list/claim/ClaimDetail.vue'),
              },
            ],
          },
          {
            name: 'FulfillmentEdit',
            path: '/prestaties/:id/wijzigen',
            meta: {
              requiresAuth: RolePermission.CLAIM_WRITE,
              isMunicipality: true,
              trackEntityId: true,
            },
            component: () => import('@/views/fulfillment/FulfillmentEdit.vue'),
          },
          {
            name: 'FloatingEdit',
            path: '/zwevend/:id/wijzigen',
            meta: {
              requiresAuth: RolePermission.CLAIM_WRITE,
              isMunicipality: true,
              trackEntityId: true,
            },
            component: () => import('@/views/fulfillment/FloatingEdit.vue'),
          },
          {
            name: 'PaymentIndex',
            path: 'betalingen',
            meta: {
              requiresAuth: RolePermission.PAYMENT_READ,
            },
            component: () => import('@/views/list/payment/PaymentIndex.vue'),
            children: [
              {
                name: 'PaymentView',
                path: '',
                component: () => import('@/views/list/payment/PaymentView.vue'),
              },
              {
                name: 'CreditorIndex',
                path: 'crediteuren',
                component: () =>
                  import('@/views/list/creditor/CreditorIndex.vue'),
                children: [
                  {
                    name: 'CreditorList',
                    path: '',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_READ,
                    },
                    component: () =>
                      import('@/views/list/creditor/CreditorList.vue'),
                  },
                  {
                    name: 'CreditorAdd',
                    path: 'nieuw',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_WRITE,
                    },
                    component: () =>
                      import('@/views/list/creditor/CreditorAdd.vue'),
                  },
                  {
                    name: 'CreditorDetail',
                    path: ':id',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_READ,
                      trackEntityId: true,
                    },
                    component: () =>
                      import('@/views/list/creditor/CreditorDetail.vue'),
                  },
                  {
                    name: 'CreditorEdit',
                    path: ':id/wijzigen',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_WRITE,
                      trackEntityId: true,
                    },
                    component: () =>
                      import('@/views/list/creditor/CreditorEdit.vue'),
                  },
                ],
              },
              {
                name: 'AccountingIndex',
                path: 'boekhouding',
                component: () =>
                  import('@/views/list/accounting/AccountingIndex.vue'),
                children: [
                  {
                    name: 'AccountingList',
                    path: '',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_READ,
                    },
                    component: () =>
                      import('@/views/list/accounting/AccountingList.vue'),
                  },
                  {
                    name: 'AccountingDetail',
                    path: ':id',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_READ,
                      trackEntityId: true,
                    },
                    component: () =>
                      import('@/views/list/accounting/AccountingDetail.vue'),
                  },
                  {
                    name: 'AccountingAdd',
                    path: 'nieuw',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_WRITE,
                    },
                    component: () =>
                      import('@/views/list/accounting/AccountingAdd.vue'),
                  },
                  {
                    name: 'AccountingEdit',
                    path: ':id/wijzigen',
                    meta: {
                      requiresAuth: RolePermission.PAYMENT_WRITE,
                      trackEntityId: true,
                    },
                    component: () =>
                      import('@/views/list/accounting/AccountingEdit.vue'),
                  },
                ],
              },
            ],
          },
          {
            name: 'IberichtIndex',
            path: 'berichten',
            meta: {
              requiresAuth: RolePermission.I_BERICHT_READ,
            },
            component: () => import('@/views/list/ibericht/IberichtIndex.vue'),
            children: [
              {
                name: 'Ibericht',
                path: '',
                component: () =>
                  import('@/views/list/ibericht/IberichtView.vue'),
              },
            ],
          },
          {
            name: 'UserIndex',
            path: 'gebruikers',
            meta: {
              requiresAuth: RolePermission.USER_READ,
            },
            component: () => import('@/views/list/user/UserIndex.vue'),
            children: [
              {
                name: 'UserList',
                path: '',
                component: () => import('@/views/list/user/UserList.vue'),
              },
              {
                name: 'UserAdd',
                path: 'nieuw',
                meta: {
                  requiresAuth: RolePermission.USER_WRITE,
                },
                component: () => import('@/views/list/user/UserAdd.vue'),
              },
              {
                name: 'UserDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () => import('@/views/list/user/UserDetail.vue'),
              },
              {
                name: 'UserLog',
                path: 'logboek',
                component: () => import('@/views/list/user/UserLog.vue'),
              },
              {
                name: 'UserEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.USER_WRITE,
                  trackEntityId: true,
                },
                component: () => import('@/views/list/user/UserEdit.vue'),
              },
            ],
          },
          {
            name: 'RoleIndex',
            path: 'rollen',
            meta: {
              requiresAuth: RolePermission.ROLE_MANAGEMENT_READ,
            },
            component: () => import('@/views/list/role/RoleIndex.vue'),
            children: [
              {
                name: 'RoleList',
                path: '',
                component: () => import('@/views/list/role/RoleList.vue'),
              },
              {
                name: 'RoleAdd',
                path: 'nieuw',
                meta: {
                  requiresAuth: RolePermission.ROLE_MANAGEMENT_WRITE,
                },
                component: () => import('@/views/list/role/RoleAdd.vue'),
              },
              {
                name: 'RoleDetail',
                path: ':id',
                meta: {
                  trackEntityId: true,
                },
                component: () => import('@/views/list/role/RoleDetail.vue'),
              },
              {
                name: 'RoleLog',
                path: 'logboek',
                component: () => import('@/views/list/role/RoleLog.vue'),
              },
              {
                name: 'RoleEdit',
                path: ':id/wijzigen',
                meta: {
                  requiresAuth: RolePermission.ROLE_MANAGEMENT_WRITE,
                  trackEntityId: true,
                },
                component: () => import('@/views/list/role/RoleEdit.vue'),
              },
            ],
          },
        ],
      },
      {
        name: 'Chats',
        path: '/chat',
        meta: {},
        component: MainView,
        children: [
          {
            name: 'ChatDashboard',
            path: '',
            meta: {},
            component: () => import('@/views/chat/ChatDashboard.vue'),
          },
          {
            name: 'ChatDetail',
            path: ':id',
            meta: {
              trackEntityId: true,
            },
            component: () => import('@/views/chat/ChatDetail.vue'),
          },
        ],
      },
      {
        name: 'Profile',
        path: '/profiel',
        component: MainView,
        children: [
          {
            name: 'ProfileSettings',
            path: 'instellingen',
            component: () => import('@/views/profile/ProfileSettings.vue'),
          },
        ],
      },
    ],
  })

  router.beforeEach(async (to, from, next) => {
    NProgress.start()

    if (
      (to.meta.isSupplier && !isSupplier()) ||
      (to.meta.isMunicipality && !isMunicipality()) ||
      (to.meta.requiresAuth && !isAuthorized(to.meta.requiresAuth))
    )
      next(from)
    else {
      let currentEntityId: string | undefined = undefined

      if (to.meta.trackEntityId) {
        // Get last item in params-object.
        // For example client/:id/allocation/:id -> allocationId is what we want to track
        // Or if a chat is being viewed in a tabbar-item, use that.

        currentEntityId =
          (to.query.ChatId as string) ??
          (to.params[
            Object.keys(to.params)[Object.keys(to.params).length - 1]
          ] as string)
      }

      useUserStore().setCurrentEntityId(currentEntityId)

      useKnowledgeBaseStore().clearKnowledgeBase()

      next()
    }
  })

  router.afterEach(() => {
    NProgress.done()
  })

  return router
}
export default initializeRouter

declare module 'vue-router' {
  interface RouteMeta {
    isMunicipality?: boolean
    isSupplier?: boolean
    requiresAuth?: RolePermission
    trackEntityId?: boolean
  }
}
