import { defineStore } from 'pinia'
import type {
  ChatEntityType,
  ChatMessage,
  ChatMessageDTO,
  ChatType,
  NewChat,
} from '@/types/chatType'
import ChatService from '@/services/ChatService'
import { checkIfChatHasUnreadMessages } from '@/utils/utils'

export const useChatStore = defineStore({
  id: 'chats',
  state: (): {
    chats: Map<string, ChatType & { timestamp?: number, outdated?: boolean }>
    userChatsLoaded: boolean
    topicChatsLoaded: boolean
    page: number
  } => ({
    chats: new Map<string, ChatType>(),
    userChatsLoaded: false,
    topicChatsLoaded: false,
    page: 1,
  }),
  actions: {
    async fetchNextPage() {
      return ChatService.getChats({
        page: this.page++,
      })
        .then(({ data }) =>
          data.forEach((x) => {
            if (!this.chats.has(x.id)) {
              this.chats.set(x.id, x)
            }

            const current = this.chats.get(x.id)

            if (current) {
              ;(async () => {
                this.chats.set(x.id, {
                  ...current,
                  hasUnreadMessages: await checkIfChatHasUnreadMessages(
                    x.messages[0],
                    x.users,
                  ),
                })
              })()
            }
          }),
        )
        .finally(() => {
          if (this.chats.size) this.userChatsLoaded = true
        })
    },

    async fetchChatsByTopicId(topicId: string) {
      return ChatService.fetchChatsByTopicId(topicId)
        .then(({ data }) =>
          data.forEach((x) => {
            if (!this.chats.has(x.id)) {
              this.chats.set(x.id, x)
            }

            const current = this.chats.get(x.id)

            if (current) {
              ;(async () => {
                this.chats.set(x.id, {
                  ...current,
                  hasUnreadMessages: await checkIfChatHasUnreadMessages(
                    x.messages[0],
                    x.users,
                  ),
                })
              })()
            }
          }),
        )
        .finally(() => {
          if (this.chats.size) this.topicChatsLoaded = true
        })
    },

    async fetchChatById(id: string, cached = true) {
      const local = this.chats.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await ChatService.getChatById(id)

        if (data) {
          this.chats.set(id, {
            ...data,
            messagePage: 2, // First page of messages is already sent with fetch of chat
            timestamp: new Date().getTime(),
          })
        }

        return data
      }

      return local
    },

    async fetchChatMessagesByChatId(id: string, mercureSync = false) {
      const chat = this.chats.get(id)

      if (!chat) throw new Error('Chat not found')

      const page = mercureSync ? 1 : chat.messagePage

      const { data } = await ChatService.getChatMessagesByChatId(id, page)

      if (data.length) {
        chat.messagePage = page + 1

        const filteredData = data.filter(
          (x) => !chat.messages.some((y) => y.id === x.id),
        )

        this.chats?.set(id, {
          ...chat,
          messages: mercureSync
            ? [...filteredData, ...chat.messages]
            : [...chat.messages, ...filteredData],
        })
      }

      return data
    },

    async pushChatToTop(ChatId: string) {
      const currentChat = this.chats.get(ChatId)
      const currentList = Array.from<ChatType>(this.chats.values())

      if (currentChat && currentList) {
        this.chats.clear()

        this.chats.set(ChatId, currentChat)

        currentList.forEach((x) => {
          if (x.id !== currentChat.id) {
            this.chats.set(x.id, x)
          }
        })
      }
    },

    async markChatAsRead(chatId: string) {
      const current = this.chats.get(chatId)

      if (current) {
        this.chats.set(chatId, {
          ...current,
          hasUnreadMessages: false,
        })
      }
    },

    async getAllowedUsers(subject: ChatEntityType, subjectId: string) {
      return (await ChatService.getAllowedUsers(subject, subjectId)).data
    },

    async sendChatMessage(chatId: string, message: ChatMessageDTO) {
      const { data } = await ChatService.addMessageToChat(chatId, message)

      const current = this.chats.get(chatId)

      if (current) {
        current.messages = [data].concat(current.messages ?? [])

        this.chats.set(chatId, current)
      }
    },

    async addNewChat(chat: NewChat) {
      const { data } = await ChatService.addNewChat(chat)

      this.chats.set(data.id, data)

      this.pushChatToTop(data.id)

      return data
    },

    async markChatAsOutdated(id: string, watching: boolean) {
      const local = this.chats.get(id)

      if (local) {
        local.outdated = true

        this.pushChatToTop(id)

        if (!watching) local.hasUnreadMessages = true

        await this.fetchChatMessagesByChatId(id, true)
      }
    },
  },
  getters: {
    getChatsByUserId: (state) => {
      return (id: string) => {
        return Array.from<ChatType>(state.chats.values()).filter((x) =>
          x.users.some((x) => x.id === id),
        )
      }
    },

    getChatMessagesAsArray: (state) => {
      return (id: string) => {
        const chat = state.chats?.get(id)

        if (chat) return Array.from<ChatMessage>(chat.messages.values())

        return []
      }
    },

    getChatsByTopicId: (state) => {
      return (topicId: string) => {
        return Array.from<ChatType>(state.chats.values()).filter((x) =>
          x.topics.some((x) => x.id === topicId),
        )
      }
    },

    userChatsAreLoaded: (state) => state.userChatsLoaded,
    topicChatsAreLoaded: (state) => state.topicChatsLoaded,

    findOrThrowChatById: (state) => {
      return (id: string) => {
        const chat = state.chats?.get(id)

        if (!chat) throw new Error('Not found')

        return chat
      }
    },
  },
})
